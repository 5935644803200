exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-manage-quotes-tsx": () => import("./../../../src/pages/admin/manage-quotes.tsx" /* webpackChunkName: "component---src-pages-admin-manage-quotes-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-quote-tsx": () => import("./../../../src/pages/quote.tsx" /* webpackChunkName: "component---src-pages-quote-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-pages-web-toys-kaprekars-calculator-tsx": () => import("./../../../src/pages/web-toys/kaprekars-calculator.tsx" /* webpackChunkName: "component---src-pages-web-toys-kaprekars-calculator-tsx" */),
  "component---src-pages-web-toys-pass-words-tsx": () => import("./../../../src/pages/web-toys/pass-words.tsx" /* webpackChunkName: "component---src-pages-web-toys-pass-words-tsx" */),
  "component---src-pages-web-toys-tsx": () => import("./../../../src/pages/web-toys.tsx" /* webpackChunkName: "component---src-pages-web-toys-tsx" */),
  "component---src-templates-contentful-post-tsx": () => import("./../../../src/templates/contentfulPost.tsx" /* webpackChunkName: "component---src-templates-contentful-post-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

